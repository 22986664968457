import { PropsWithChildren, useCallback } from "react";
import iconCancelWhite from "../../assets/cancel_white.png";
import { useRecoilValue } from "recoil";
import { atom__isMobile } from "../../lib/recoil/common.atom";

interface IProps extends PropsWithChildren {
  visible: boolean;
  onClickOuter: () => void;
  title: string;
  innerStyle?: React.CSSProperties;
}

const ModalBaseYellow = (props: IProps) => {
  const isMobile = useRecoilValue(atom__isMobile);

  const close = useCallback(() => {
    props.onClickOuter();
  }, [props]);

  return (
    <div
      className="modal-yellow-container"
      style={{ display: props.visible ? "flex" : "none" }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
        onClick={close}
      />

      <div
        className="flex-col-center-center inner"
        style={isMobile ? { width: "100%" } : {}}
      >
        {/* header */}
        <div
          className="flex-row-center-center"
          style={{
            position: "relative",
            width: "100%",
            height: "60px",
            backgroundColor: "var(--yellow-dark)",
          }}
        >
          <h2>{props.title}</h2>
          <div
            className="flex-row-center-center"
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              width: "60px",
              height: "60px",
              cursor: "pointer",
            }}
            onClick={close}
          >
            <img
              src={iconCancelWhite}
              style={{ width: "28px", height: "28px" }}
            />
          </div>
        </div>

        {props.children}
      </div>
    </div>
  );
};

export default ModalBaseYellow;
