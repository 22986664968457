import { atom } from "recoil";
import { TMe } from "../types/user.type";
import { TMapFilter } from "../types/map.type";

export const atom__me = atom<TMe | null>({
  key: "me",
  default: null,
});

export const atom__globalPostFilter = atom<TMapFilter>({
  key: "globalPostFilter",
  default: {
    address2: "x",
    address3: "x",
    apartKind: "x",
    postKind: "x",
    houseCount: "x-x",
    enterAge: "x-x",
    amount: "x-x",
    size: "x-x",
  },
});

export const atom__isMobile = atom({
  key: "isMobile",
  default: false,
});
