import markerBackground from "../../assets/marker-background.png";
import markerPostcountBackground from "../../assets/marker-postcount.png";

const CustomMarker = ({
  cost,
  postCount,
}: {
  cost: string;
  postCount: string;
}) => {
  return `
  <div class="customer-marker-container">
    <div class="customer-marker">
        <img src=${markerBackground} />
        <span class="customer-marker-cost">${cost}</span>
    </div>
    <div class="customer-marker-postcount">
        <img src=${markerPostcountBackground} />
        <span>${postCount}</span>
    </div>
  </div>`;

  //   return `<div style='background-color: red'></div>`;
};
export default CustomMarker;
