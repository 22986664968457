import { useRecoilState, useSetRecoilState } from "recoil";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  atom__changePhoneModalInfo,
  atom__findIdModalInfo,
  atom__simpleAlertModalInfo,
  atom__successModalInfo,
} from "../../lib/recoil/modal.atom";
import BaseButton from "../Button/Base";
import ModalBaseYellow from "./BaseYellow";
import {
  api_changePhone,
  api_confirmPhoneCode,
  api_findId,
  api_sendPhoneCode,
} from "../../api/auth";
import InputWithButton from "../InputWithButton";
import { regAuthCode, regPhone } from "../../lib/validator";

const ModalFindId = () => {
  const [r__modalInfo, r__setModalInfo] = useRecoilState(atom__findIdModalInfo);

  const r__setSuccessModalInfo = useSetRecoilState(atom__successModalInfo);
  const r__setSimpleAlertModalInfo = useSetRecoilState(
    atom__simpleAlertModalInfo
  );

  const [name, setName] = useState("");
  /** 입력한 휴대폰번호 */
  const [phone, setPhone] = useState("");
  /** 인증번호 발송한 휴대폰번호 */
  const [authingPhone, setAuthingPhone] = useState("");
  /** 인증완료된 휴대폰번호. phone값은 '인증번호 발송', '인증하기' 버튼을 누르면 authPhone -> authedPhone으로 옮겨감. */
  const [authedPhone, setAuthedPhone] = useState("");
  const [authCode, setAuthCode] = useState("");

  const [phoneAlertDesc, setPhoneAlertDesc] = useState("");
  const [codeAlertDesc, setCodeAlertDesc] = useState("");

  // 인증 성공 시 응답받는 historyId. POST /user/find/id API 호출 시 인자로 보냄.
  const refAuthHistoryId = useRef("");

  useEffect(() => {
    if (!r__modalInfo) {
      setPhone("");
      setAuthCode("");
      setAuthingPhone("");
      setAuthedPhone("");
      setPhoneAlertDesc("");
      setCodeAlertDesc("");
      setName("");
      return;
    }
  }, [r__modalInfo]);

  // 자동하이픈
  useEffect(() => {
    if (phone.length === 10) {
      setPhone(phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"));
    }
    if (phone.length === 13) {
      setPhone(
        phone.replace(/-/g, "").replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3")
      );
    }
  }, [phone]);

  const close = useCallback(() => {
    r__setModalInfo(null);
  }, []);

  const find = async () => {
    if (!refAuthHistoryId.current) return;

    const res = await api_findId({
      authHistoryId: refAuthHistoryId.current,
      name,
    });
    if (!res) return;

    close();
    r__setSuccessModalInfo({
      title: "아이디 찾기",
      desc: ["고객님의 아이디가 문자로 발송되었습니다."],
    });
  };

  const sendCode = () => {
    if (!regPhone.test(phone)) {
      setPhoneAlertDesc("올바른 휴대폰번호를 입력해주세요.");
      return;
    }

    api_sendPhoneCode(phone).then((res) => {
      if (!res) return;

      r__setSimpleAlertModalInfo({
        desc: ["인증번호가 발송되었습니다."],
        btnColor: "var(--yellow-dark)",
      });
      setPhoneAlertDesc("인증번호가 발송되었습니다.");
      setTimeout(() => {
        setPhoneAlertDesc("");
      }, 5000);

      setAuthingPhone(phone);
    });
  };
  const confirmCode = () => {
    if (!regAuthCode.test(authCode)) {
      setCodeAlertDesc("올바른 인증번호를 입력해주세요.");
      return;
    } else setCodeAlertDesc("");

    api_confirmPhoneCode({ phone, code: authCode }).then((res) => {
      if (!res) return;
      refAuthHistoryId.current = res.historyId;

      r__setSimpleAlertModalInfo({
        desc: ["인증 완료되었습니다."],
        btnColor: "var(--yellow-dark)",
      });

      setAuthedPhone(authingPhone);
      setAuthingPhone("");
    });
  };

  return (
    <ModalBaseYellow
      visible={!!r__modalInfo}
      onClickOuter={close}
      title="아이디 찾기"
    >
      <div
        className="flex-col-between-center"
        style={{
          padding: "20px",
          width: "100%",
          flex: 1,
        }}
      >
        <div style={{ width: "100%" }}>
          <label>이름</label>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{}}
            placeholder="이름 입력"
          />
          <p className="alert-desc" style={{ height: "24px" }}></p>
          <label>휴대전화</label>
          <InputWithButton
            inputProps={{
              type: "text",
              placeholder: "010-0000-0000",
              maxLength: 13,
              readOnly: phone.length > 0 && phone === authedPhone,
              value: phone,
              onChange: (e) => {
                setPhone(e.target.value);
              },
            }}
            buttonProps={{
              text: "인증번호 발송",
              disabled: phone === authedPhone,
              onClick: sendCode,
            }}
          />
          <p className="alert-desc" style={{ height: "24px" }}>
            {phoneAlertDesc}
          </p>

          <label>인증번호</label>
          <InputWithButton
            inputProps={{
              type: "text",
              placeholder: "인증번호 4자리",
              maxLength: phone !== authedPhone ? 4 : undefined,
              value:
                !phone.length || phone !== authedPhone
                  ? authCode
                  : "인증이 완료되었습니다.",
              onChange: (e) => {
                setAuthCode(e.target.value);
              },
            }}
            buttonProps={{
              text: "인증하기",
              disabled: !authingPhone || authCode.length !== 4,
              onClick: confirmCode,
            }}
          />
          <p className="alert-desc" style={{ height: "60px" }}>
            {codeAlertDesc}
          </p>
        </div>

        <BaseButton
          text="아이디 찾기"
          onClick={find}
          disabled={!authedPhone.length || !name.length}
        />
      </div>
    </ModalBaseYellow>
  );
};

export default ModalFindId;
