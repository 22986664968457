import iconMedalGold from "../../assets/medal_gold.png";
import iconMedalSilver from "../../assets/medal_silver.png";
import iconMedalBronze from "../../assets/medal_bronze.png";

interface IProps {
  worth: number;
  size: number;
  style?: React.CSSProperties;
}
const BadgeMedal = (props: IProps) => {
  return (
    <img
      src={
        props.worth === 50
          ? iconMedalGold
          : props.worth === 30
          ? iconMedalSilver
          : iconMedalBronze
      }
      style={{
        width: `${props.size}px`,
        height: `${props.size}px`,
        ...(props.style ?? {}),
      }}
    />
  );
};

export default BadgeMedal;
