import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { atom__notificationModalInfo } from "../../lib/recoil/modal.atom";
import { atom__me } from "../../lib/recoil/common.atom";
import { useCallback } from "react";
import useIsMobile from "../../lib/hooks/useIsMobile";
import BaseButtonOutline from "../Button/Outline";
import BaseButton from "../Button/Base";
import { api_stopNotification } from "../../api/user";
import { api_noticeLast } from "../../api/notice";

/**
 *
 * NOTE: 지도페이지에서는 <GlobalPostFilter /> 아래에, 나머지 페이지에서는 GNB 아래에 위치함.
 * 이에 따라 이 모달은 Layout.tsx에 안들어있고 Map.tsx와 GNB/pc/index.tsx, GNB/mobile.tsx에 들어있음
 */
export const ModalNotification = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const [r__modalInfo, r__setModalInfo] = useRecoilState(
    atom__notificationModalInfo
  );
  const [r__me, r__setMe] = useRecoilState(atom__me);

  const close = useCallback(() => {
    r__setModalInfo(null);
  }, []);

  const onClickStop = async () => {
    close();
    api_stopNotification();
  };
  const onClickOk = async () => {
    close();
    api_noticeLast().then((res) => {
      if (!res) return;
      // unreadNotification=false로 바꾸고 공지사항 상세로 이동
      r__setMe((prev) => {
        if (!prev) return null;
        return { ...prev, unreadNotification: false };
      });
      navigate(`/news/notice/${res.lastNoticeId}`);
    });
  };

  if (!r__modalInfo) return null;

  return (
    <div
      style={{
        padding: "10px",
        position: "absolute",
        bottom: 0,
        right: 0,
        transform: "translateY(100%)",
        zIndex: 1,
        ...(isMobile ? { width: "100%" } : {}),
      }}
    >
      <div
        style={{
          backgroundColor: "#fff",
          width: isMobile ? "100%" : "400px",
          padding: "30px 24px",
          borderRadius: "20px",
          textAlign: "center",
          boxShadow: "1px 2px 4px #99999977",
        }}
      >
        <h2>알림</h2>
        <p
          className="text-small"
          style={{
            margin: "20px 0",
          }}
        >
          신규 공지사항을 확인하세요.
        </p>
        <div className="flex-row-between-center" style={{ gap: "20px" }}>
          <BaseButtonOutline
            text="오늘 그만보기"
            style={{ flex: 1 }}
            onClick={onClickStop}
          />
          <BaseButton text="확인" style={{ flex: 1 }} onClick={onClickOk} />
        </div>
      </div>
    </div>
  );
};

export default ModalNotification;
