import Footer from "../../components/Footer";
import { useRef } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { atom__isMobile, atom__me } from "../../lib/recoil/common.atom";
import iconProfileDefault from "../../assets/profile-default-big.png";
import iconCamera from "../../assets/camera.png";
import SmallButtonOutline from "../../components/Button/SmallOutline";
import { api_changeProfile, api_logout, api_me } from "../../api/auth";
import InputWithButton from "../../components/InputWithButton";
import {
  atom__changeOfficeModalInfo,
  atom__changePhoneModalInfo,
  atom__changePwModalInfo,
  atom__simpleConfirmModalInfo,
  atom__successModalInfo,
} from "../../lib/recoil/modal.atom";
import { uploadFile } from "../../lib/upload-file";

const ProfilePage = () => {
  const isMobile = useRecoilValue(atom__isMobile);

  const r__setSimpleConfirmModalInfo = useSetRecoilState(
    atom__simpleConfirmModalInfo
  );
  const r__setSuccessModalInfo = useSetRecoilState(atom__successModalInfo);
  const r__setChangePwModalInfo = useSetRecoilState(atom__changePwModalInfo);
  const r__setChangePhoneModalInfo = useSetRecoilState(
    atom__changePhoneModalInfo
  );
  const r__setChangeOfficeModalInfo = useSetRecoilState(
    atom__changeOfficeModalInfo
  );
  const [r__me, r__setMe] = useRecoilState(atom__me);

  const refFileInput = useRef<HTMLInputElement>(null);

  const changeProfile = async (file: File) => {
    const uploaded = await uploadFile(file);
    if (!uploaded) return;

    const res = await api_changeProfile({ url: uploaded.url });
    if (!res) return;

    r__setSuccessModalInfo({ desc: ["프로필사진이 변경되었습니다."] });
    reFetchMe();
  };
  const changePw = () => {
    r__setChangePwModalInfo({
      onChange: reFetchMe,
    });
  };
  const changePhone = () => {
    r__setChangePhoneModalInfo({
      onChange: reFetchMe,
    });
  };
  const changeOffice = () => {
    r__setChangeOfficeModalInfo({
      onChange: reFetchMe,
    });
  };

  const reFetchMe = () => {
    api_me().then((res) => {
      if (!res) return;
      r__setMe(res.me);
    });
  };

  const logout = async () => {
    r__setSimpleConfirmModalInfo({
      title: "로그아웃",
      desc: ["로그아웃하시겠습니까?"],
      btnRightText: "로그아웃",
      onClickBtnLeft: () => {
        r__setSimpleConfirmModalInfo(null);
      },
      onClickBtnRight: () => {
        r__setSimpleConfirmModalInfo(null);
        api_logout().then((res) => {
          if (!res) return;

          window.location.replace("/map");
        });
      },
    });
  };

  return (
    <div className="flex-col" style={{ height: "100%", flex: 1 }}>
      <div
        className="flex-col-start-center"
        style={{ flex: 1, padding: "16px" }}
      >
        {/* content container */}
        <div style={{ width: isMobile ? "100%" : "770px" }}>
          <h2 style={{ margin: "10px 0 40px 0" }}>내 정보</h2>
          {/* 프로필사진, 이름/아이디/이메일 */}
          <div
            className="flex-row-center-center"
            style={{ marginBottom: "40px" }}
          >
            {/* 프로필사진 */}
            <div
              style={{
                width: isMobile ? "72px" : "150px",
                height: isMobile ? "72px" : "150px",
                borderRadius: "150px",
                border: "1px solid var(--yellow-light)",
                position: "relative",
              }}
            >
              <div
                style={{
                  width: "2px",
                  height: "2px",
                  overflow: "hidden",
                  position: "absolute",
                  left: "50%",
                  bottom: "50%",
                }}
              >
                <input
                  ref={refFileInput}
                  type="file"
                  style={{
                    width: "2px",
                    height: "2px",
                  }}
                  onChange={(e) => {
                    if (!e.target.files?.length) return;

                    changeProfile(e.target.files[0]);
                  }}
                />
              </div>

              <img
                src={r__me?.profileImgUrl || iconProfileDefault}
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "150px",
                }}
                onClick={() => {
                  refFileInput.current?.click();
                }}
              />

              {!isMobile && (
                <img
                  src={iconCamera}
                  style={{
                    position: "absolute",
                    right: "-24px",
                    bottom: 0,
                    width: "48px",
                    height: "48px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    refFileInput.current?.click();
                  }}
                />
              )}
            </div>
            {/* 이름/아이디/이메일 */}
            <div
              className="flex-col"
              style={{ marginLeft: isMobile ? "22px" : "83px", flex: 1 }}
            >
              <div
                className="flex-row-between-center"
                style={isMobile ? { flex: 1 } : { width: "344px" }}
              >
                <p className="label-small">이름</p>
                <p className="value-small">{r__me?.name}</p>
              </div>
              <div
                className="flex-row-between-center"
                style={
                  isMobile
                    ? { flex: 1, margin: "8px 0" }
                    : { width: "344px", margin: "26px 0" }
                }
              >
                <p className="label-small">아이디</p>
                <p className="value-small">{r__me?.uid}</p>
              </div>
              <div
                className="flex-row-between-center"
                style={isMobile ? { flex: 1 } : { width: "344px" }}
              >
                <p className="label-small">이메일</p>
                <p className="value-small">{r__me?.email}</p>
              </div>
            </div>
          </div>

          <div className="flex-row" style={{ flexWrap: "wrap", gap: "40px" }}>
            <div style={isMobile ? { width: "100%" } : { flex: 1 }}>
              <label>비밀번호</label>
              <InputWithButton
                inputProps={{
                  readOnly: true,
                }}
                buttonProps={{
                  text: "변경하기",
                  onClick: changePw,
                }}
                style={{ marginBottom: "36px" }}
              />

              <label>휴대전화</label>
              <InputWithButton
                inputProps={{
                  value: r__me?.phone ?? "",
                  readOnly: true,
                }}
                buttonProps={{
                  text: "변경하기",
                  onClick: changePhone,
                }}
                style={{ marginBottom: "36px" }}
              />
            </div>
            <div style={isMobile ? { width: "100%" } : { flex: 1 }}>
              <label>소속사무소</label>
              <div style={{ marginBottom: "71px" }}>
                {r__me?.level === 100 ? (
                  <input value={r__me?.office?.name} />
                ) : (
                  <InputWithButton
                    inputProps={{
                      value:
                        r__me?.changingOfficeName || r__me?.office?.name || "",
                      readOnly: true,
                    }}
                    buttonProps={{
                      text: !!r__me?.changingOfficeName
                        ? "변경심사중"
                        : "변경하기",
                      disabled: !!r__me?.changingOfficeName,
                      onClick: changeOffice,
                    }}
                  />
                )}
              </div>

              <div className="flex-row" style={{ justifyContent: "flex-end" }}>
                <SmallButtonOutline
                  text="로그아웃"
                  onClick={logout}
                  width={100}
                  color="tomato"
                  style={{ marginBottom: "36px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProfilePage;
