import {
  TApartDetailOnMap,
  TApartListOnMapPayload,
} from "../lib/types/apart.type";
import { TDistrict, TMarkerApartInfo } from "../lib/types/map.type";
import axios from "./axios";

export const api_searchApart = async (keyword: string) => {
  const res = await axios.get("/apart/search", { params: { keyword } });
  return res.data?.data as {
    list: { id: string; name: string; address: string }[];
  };
};

export const api_districtList = async () => {
  const res = await axios.get("/district/all");
  return res.data?.data as {
    as2List: TDistrict[];
    as3List: TDistrict[];
  };
};

export const api_listOnMap = async (payload: TApartListOnMapPayload) => {
  const res = await axios.post("/apart/map", payload);
  if (!res.data.success) return { list: [] };
  return res.data.data as {
    list: TMarkerApartInfo[];
  };
};

export const api_apartDetailOnMap = async (apartId: string) => {
  const res = await axios.get("/apart-on-map", { params: { apartId } });
  if (!res.data.success) return null;
  return res.data.data as TApartDetailOnMap;
};
