import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { atom__isMobile, atom__me } from "../../lib/recoil/common.atom";
import {
  atom__alertModalInfo,
  atom__confirmModalInfo,
  atom__leaveModalInfo,
  atom__successModalInfo,
} from "../../lib/recoil/modal.atom";
import SmallButton from "../../components/Button/Small";
import { TMembership, TUserMembership } from "../../lib/types/membership.type";
import {
  api_membershipMine,
  api_membershipPurchase,
} from "../../api/membership";
import {
  KAKAO_LINK,
  printCostPreferMan,
  printFormattedDate,
} from "../../lib/util";
import { api_leave } from "../../api/auth";
import { useNavigate } from "react-router-dom";

interface IMembershipItemProps {
  membership: TMembership;
  /** 현재 내 멤버십으로 적용되어 있는지. 적용되어있다면 '구매신청' 버튼 disabled */
  isMine: boolean;
  onClickPurchase: () => void;
  style?: React.CSSProperties;
}
const MembershipItem = (props: IMembershipItemProps) => {
  return (
    <div
      className="flex-col"
      style={{
        width: "340px",
        maxHeight: "fit-content",
        ...(props.style ?? {}),
      }}
    >
      <div
        style={{
          border: "1px solid var(--yellow-light)",
          width: "100%",
          padding: "20px 20px 40px",
          borderRadius: "8px",
          marginBottom: "20px",
        }}
      >
        <p className="text-medium" style={{ marginBottom: "24px" }}>
          {props.membership.name}
        </p>
        <div className="flex-row-between-center">
          <h2>{printCostPreferMan(props.membership.amount)}</h2>
          <p className="text-medium" style={{ color: "var(--border-gray)" }}>
            {props.membership.desc}
          </p>
        </div>
      </div>

      <SmallButton
        text={props.isMine ? "적용됨" : "구매 신청"}
        disabled={props.isMine}
        onClick={props.onClickPurchase}
        width={100}
        style={{ width: "100%" }}
        color="beige"
      />
    </div>
  );
};

// ------------

const MembershipPage = () => {
  const isMobile = useRecoilValue(atom__isMobile);
  const navigate = useNavigate();

  const r__setSuccessModalInfo = useSetRecoilState(atom__successModalInfo);
  const r__setAlertModalInfo = useSetRecoilState(atom__alertModalInfo);
  const r__setConfirmModalInfo = useSetRecoilState(atom__confirmModalInfo);
  const r__setLeaveModalInfo = useSetRecoilState(atom__leaveModalInfo);

  const [r__me, r__setMe] = useRecoilState(atom__me);

  const [mine, setMine] = useState<TUserMembership | null>(null);
  const [membershipItems, setMembershipItems] = useState<TMembership[]>([]);

  useEffect(() => {
    _fetch();
  }, []);

  const _fetch = () => {
    api_membershipMine().then((res) => {
      if (!res) return;

      setMine(res.mine);
      setMembershipItems(res.membershipItems);
    });
  };

  const buy = async (membershipId: string, isContinue: boolean) => {
    if (r__me?.isSale) return alert("영업직원 계정은 제한된 기능입니다.");

    const res = await api_membershipPurchase(membershipId);
    if (!res) return;
    if (res.success === false) {
      r__setAlertModalInfo({ title: "실패", desc: [res.err.msg] });
      return;
    }

    r__setSuccessModalInfo({
      desc: [
        isContinue
          ? "연장신청이 접수되었습니다."
          : "구매신청이 완료되었습니다.",
        "담당자 안내 예정입니다.",
      ],
    });
  };

  const refund = async () => {
    if (r__me?.isSale) return alert("영업직원 계정은 제한된 기능입니다.");
    if (!mine) return;

    // 채널톡 연결
    window.open(KAKAO_LINK, "_blank");

    // const res = await api_membershipRefund(mine.id);
    // if (!res) return;

    // r__setSuccessModalInfo({
    //   desc: ["환불신청이 접수되었습니다.", "담당자 안내 예정입니다."],
    // });
  };

  const leave = async () => {
    if (!r__me) return;

    if (r__me?.isSale) return alert("영업직원 계정은 제한된 기능입니다.");

    // // 개공은 별도의 팝업
    // if (r__me.level === 100) {
    //   r__setLeaveModalInfo(true);
    //   return;
    // }

    // 소공,보조원은 컨펌팝업
    r__setConfirmModalInfo({
      title: "탈퇴",
      desc: [
        "*일반권 멤버십 회원의 경우, 회원탈퇴에 앞서 환불요청을 클릭,",
        "고객센터에 환불신청 요청을 우선적으로 진행 부탁드립니다.",
        "*탈퇴시 기록확인이 제한됩니다.",
      ],
      theme: "alert",
      btnRightText: "탈퇴",
      onClickBtnRight: async () => {
        const res = await api_leave();
        if (!res) return;

        r__setMe(null);
        r__setConfirmModalInfo(null);
        r__setSuccessModalInfo({
          desc: ["회원탈퇴가 완료되었습니다."],
          onClickBtn: () => {
            r__setSuccessModalInfo(null);
            navigate("/map");
          },
        });
      },
    });
  };

  return (
    <div style={{ padding: "20px" }}>
      <div>
        <h2>멤버십</h2>
        {/* 내 멤버십 정보와 버튼들: 모바일,pc 따로 마크업 */}
        {!isMobile ? (
          <div
            className="flex-row"
            style={{
              alignItems: "flex-start",
              height: "80px",
              margin: "40px 0 60px",
            }}
          >
            {/* 내멤버십 */}
            <div
              className="flex-row-start-center"
              style={{ marginRight: "10px" }}
            >
              <p
                className="label-small"
                style={{ width: "120px", textAlign: "left" }}
              >
                내 멤버십
              </p>
              <p className="value-big">{mine?.membershipInfo.name ?? "-"}</p>
            </div>

            {/* 시작일,종료일 */}
            <div
              className="flex-col-between-start"
              style={{ height: "100%", marginRight: "20px" }}
            >
              <div
                className="flex-row-start-center"
                //   style={{ marginBottom: "30px" }}
              >
                <p
                  className="label-small"
                  style={{ width: "120px", textAlign: "left" }}
                >
                  시작일
                </p>
                <p className="value-big">
                  {mine?.startDate
                    ? printFormattedDate(new Date(mine.startDate))
                    : "-"}
                </p>
              </div>
              <div className="flex-row-start-center">
                <p
                  className="label-small"
                  style={{ width: "120px", textAlign: "left" }}
                >
                  만료일
                </p>
                <p className="value-big">
                  {mine?.endDate
                    ? printFormattedDate(new Date(mine.endDate))
                    : "-"}
                </p>
              </div>
            </div>

            {/* 버튼들 */}
            <div
              className="flex-col"
              style={{ height: "100%", justifyContent: "flex-end" }}
            >
              <div className="flex-row">
                <SmallButton
                  text="연장 신청"
                  onClick={() => {
                    if (!mine) return;
                    buy(mine.membershipId, true);
                  }}
                  disabled={
                    // 현재 내 멤버십이 없거나, 구매가능횟수가 1번으로 제한된 경우
                    !mine || mine.membershipInfo.availableCount === 1
                  }
                  color="beige"
                  width={100}
                />
                <SmallButton
                  text="환불신청"
                  onClick={refund}
                  disabled={!mine || mine.membershipInfo.kind === "promotion"}
                  color="beige"
                  width={100}
                  style={{ margin: "0 14px" }}
                />
                <SmallButton
                  text="회원탈퇴"
                  onClick={leave}
                  color="beige"
                  width={100}
                />
              </div>
              {mine?.membershipInfo.availableCount === 1 && (
                <p className="alert-desc" style={{ marginTop: "14px" }}>
                  *해당 상품은 이벤트 상품으로 연장이 불가합니다.
                </p>
              )}
            </div>
          </div>
        ) : (
          <div style={{ margin: "40px 0" }}>
            <div
              className="flex-row-between-center"
              style={{ marginBottom: "16px" }}
            >
              <p className="text-small bold">내 멤버십</p>
              <p className="text-small">{mine?.membershipInfo.name ?? "-"}</p>
            </div>
            <div
              className="flex-row-between-center"
              style={{ marginBottom: "16px" }}
            >
              <p className="text-small bold">시작일</p>
              <p className="text-small">
                {mine?.startDate
                  ? printFormattedDate(new Date(mine.startDate))
                  : "-"}
              </p>
            </div>
            <div
              className="flex-row-between-center"
              style={{ marginBottom: "16px" }}
            >
              <p className="text-small bold">종료일</p>
              <p className="text-small">
                {mine?.endDate
                  ? printFormattedDate(new Date(mine.endDate))
                  : "-"}
              </p>
            </div>
            <div className="flex-row-between-center">
              <SmallButton
                text="연장 신청"
                onClick={() => {
                  if (!mine) return;
                  buy(mine.membershipId, true);
                }}
                disabled={
                  // 현재 내 멤버십이 없거나, 구매가능횟수가 1번으로 제한된 경우
                  !mine || mine.membershipInfo.availableCount === 1
                }
                color="beige"
                width={100}
              />
              <SmallButton
                text="환불신청"
                onClick={refund}
                disabled={!mine || mine.membershipInfo.kind === "promotion"}
                color="beige"
                width={100}
                style={{ margin: "0 14px" }}
              />
              <SmallButton
                text="회원탈퇴"
                onClick={leave}
                color="beige"
                width={100}
              />
            </div>
            {mine?.membershipInfo.availableCount === 1 && (
              <p className="alert-desc" style={{ marginTop: "14px" }}>
                *해당 상품은 이벤트 상품으로 연장이 불가합니다.
              </p>
            )}
          </div>
        )}

        <h2 style={{ marginBottom: "40px" }}>멤버십 상품</h2>
        <div
          className="flex-row-start-center"
          style={{ gap: "40px", flexWrap: "wrap" }}
        >
          {membershipItems.map((m) => {
            return (
              <MembershipItem
                membership={m}
                isMine={m.id === mine?.membershipId}
                onClickPurchase={() => {
                  buy(m.id, false);
                }}
                style={
                  isMobile
                    ? {
                        marginBottom: "20px",
                        width: "100%",
                      }
                    : {
                        marginBottom: "40px",
                      }
                }
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MembershipPage;
