import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IProps {
  items: { label: string; value: string }[];
  value: string;
  onChange: (value: string) => void;
  style?: React.CSSProperties;
}
const Select = (props: IProps) => {
  return (
    <div
      className="select-container"
      style={{
        width: "100%",
        borderColor: "var(--border-gray)",
        ...(props.style ?? {}),
      }}
    >
      <select
        onChange={(e) => props.onChange(e.target.value)}
        value={props.value}
        style={{
          color: props.value === "none" ? "var(--border-gray)" : "#000",
          textAlign: "left",
          width: "100%",
        }}
      >
        {props.items.map((item) => (
          <option key={item.value} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>

      <span style={{ marginRight: "12px", pointerEvents: "none" }}>
        <FontAwesomeIcon icon={faCaretDown} color="var(--yellow-dark)" />
      </span>
    </div>
  );
};

export default Select;
