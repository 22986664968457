export const GNB_MENU = [
  {
    category: "지도",
    path: "/map",
    items: [],
    authorizedLevel: 0,
    needMembership: false,
  },
  {
    category: "중개사무소",
    path: "/office",
    items: [
      {
        name: "배지(지정단지)",
        path: "/office/badge",
        authorizedLevel: 100,
        needMembership: true,
      },
      {
        name: "인력관리",
        path: "/office/employee",
        authorizedLevel: 100,
        needMembership: true,
      },
      {
        name: "등록매물",
        path: "/office/post",
        authorizedLevel: 1,
        needMembership: true,
      },
    ],
    authorizedLevel: 1,
    needMembership: true,
  },
  {
    category: "매물등록",
    path: "/post",
    items: [],
    authorizedLevel: 0,
    needMembership: true,
  },
  {
    category: "마이페이지",
    path: "/my",
    items: [
      {
        name: "내 정보",
        path: "/my/profile",
        authorizedLevel: 1,
        needMembership: false,
      },
      {
        name: "멤버십",
        path: "/my/membership",
        authorizedLevel: 1,
        needMembership: false,
      },
      {
        name: "찜한 매물 목록",
        path: "/my/wish",
        authorizedLevel: 1,
        needMembership: true,
      },
    ],
    authorizedLevel: 1,
    needMembership: false,
  },
  {
    category: "소식",
    path: "/news",
    items: [
      {
        name: "뉴스(전국)",
        path: "/news/global",
        authorizedLevel: 1,
        needMembership: true,
      },
      {
        name: "뉴스(지역)",
        path: "/news/region",
        authorizedLevel: 1,
        needMembership: true,
      },
      {
        name: "공지사항",
        path: "/news/notice",
        authorizedLevel: 1,
        needMembership: true,
      },
    ],
    authorizedLevel: 1,
    needMembership: true,
  },
];
