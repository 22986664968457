import { useRecoilState, useSetRecoilState } from "recoil";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  atom__changePhoneModalInfo,
  atom__findIdModalInfo,
  atom__findPwModalInfo,
  atom__simpleAlertModalInfo,
  atom__successModalInfo,
} from "../../lib/recoil/modal.atom";
import BaseButton from "../Button/Base";
import ModalBaseYellow from "./BaseYellow";
import {
  api_changePhone,
  api_confirmPhoneCode,
  api_findId,
  api_findPw,
  api_sendPhoneCode,
} from "../../api/auth";
import InputWithButton from "../InputWithButton";
import { regAuthCode, regPhone } from "../../lib/validator";
import iconEyeOn from "../../assets/eye_on.png";
import iconEyeOff from "../../assets/eye_off.png";

const ModalFindPw = () => {
  const [r__modalInfo, r__setModalInfo] = useRecoilState(atom__findPwModalInfo);

  const r__setSuccessModalInfo = useSetRecoilState(atom__successModalInfo);
  const r__setSimpleAlertModalInfo = useSetRecoilState(
    atom__simpleAlertModalInfo
  );

  const [name, setName] = useState("");
  /** 입력한 휴대폰번호 */
  const [phone, setPhone] = useState("");
  /** 인증번호 발송한 휴대폰번호 */
  const [authingPhone, setAuthingPhone] = useState("");
  /** 인증완료된 휴대폰번호. phone값은 '인증번호 발송', '인증하기' 버튼을 누르면 authPhone -> authedPhone으로 옮겨감. */
  const [authedPhone, setAuthedPhone] = useState("");
  const [authCode, setAuthCode] = useState("");

  const [phoneAlertDesc, setPhoneAlertDesc] = useState("");
  const [codeAlertDesc, setCodeAlertDesc] = useState("");

  // auth: 휴대폰인증 단계, change: 새로운 비밀번호 입력 단계
  const [phase, setPhase] = useState<"auth" | "change">("auth");

  const [changePw, setChangePw] = useState("");
  const [changePwRe, setChangePwRe] = useState("");
  const [changePwSee, setChangePwSee] = useState(false);
  const [changePwReSee, setChangePwReSee] = useState(false);
  const [alertDesc, setAlertDesc] = useState("");

  // 인증 성공 시 응답받는 historyId. POST /user/find/id API 호출 시 인자로 보냄.
  const refAuthHistoryId = useRef("");

  useEffect(() => {
    if (!r__modalInfo) {
      setPhone("");
      setAuthCode("");
      setAuthingPhone("");
      setAuthedPhone("");
      setPhoneAlertDesc("");
      setCodeAlertDesc("");
      setName("");

      setPhase("auth");
      setChangePw("");
      setChangePwRe("");
      setChangePwSee(false);
      setChangePwReSee(false);
      setAlertDesc("");
      return;
    }
  }, [r__modalInfo]);

  // 자동하이픈
  useEffect(() => {
    if (phone.length === 10) {
      setPhone(phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"));
    }
    if (phone.length === 13) {
      setPhone(
        phone.replace(/-/g, "").replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3")
      );
    }
  }, [phone]);

  // alert description 지정
  useEffect(() => {
    if (!changePw && !changePwRe) return setAlertDesc("");

    setAlertDesc(
      changePw === changePwRe ? "" : "*비밀번호가 일치하지 않습니다."
    );
  }, [changePw, changePwRe]);

  const close = useCallback(() => {
    r__setModalInfo(null);
  }, []);

  const find = async () => {
    if (!refAuthHistoryId.current) return;

    const res = await api_findPw({
      authHistoryId: refAuthHistoryId.current,
      name,
      pw: changePw,
      pwRe: changePwRe,
    });
    if (!res) return;

    close();
    r__setSuccessModalInfo({
      desc: ["비밀번호가 변경되었습니다."],
    });
  };

  const sendCode = () => {
    if (!regPhone.test(phone)) {
      setPhoneAlertDesc("올바른 휴대폰번호를 입력해주세요.");
      return;
    }

    api_sendPhoneCode(phone).then((res) => {
      if (!res) return;

      r__setSimpleAlertModalInfo({
        desc: ["인증번호가 발송되었습니다."],
        btnColor: "var(--yellow-dark)",
      });
      setPhoneAlertDesc("인증번호가 발송되었습니다.");
      setTimeout(() => {
        setPhoneAlertDesc("");
      }, 5000);

      setAuthingPhone(phone);
    });
  };
  const confirmCode = () => {
    if (!regAuthCode.test(authCode)) {
      setCodeAlertDesc("올바른 인증번호를 입력해주세요.");
      return;
    } else setCodeAlertDesc("");

    api_confirmPhoneCode({ phone, code: authCode }).then((res) => {
      if (!res) return;
      refAuthHistoryId.current = res.historyId;

      r__setSimpleAlertModalInfo({
        desc: ["인증 완료되었습니다."],
        btnColor: "var(--yellow-dark)",
      });

      setAuthedPhone(authingPhone);
      setAuthingPhone("");
    });
  };

  return (
    <ModalBaseYellow
      visible={!!r__modalInfo}
      onClickOuter={close}
      title="비밀번호 변경"
    >
      <div
        className="flex-col-between-center"
        style={{
          padding: "20px",
          width: "100%",
          flex: 1,
        }}
      >
        <div style={{ width: "100%" }}>
          {phase === "auth" ? (
            <>
              <label>이름</label>
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                style={{}}
                placeholder="이름 입력"
              />
              <p className="alert-desc" style={{ height: "24px" }}></p>
              <label>휴대전화</label>
              <InputWithButton
                inputProps={{
                  type: "text",
                  placeholder: "010-0000-0000",
                  maxLength: 13,
                  readOnly: phone.length > 0 && phone === authedPhone,
                  value: phone,
                  onChange: (e) => {
                    setPhone(e.target.value);
                  },
                }}
                buttonProps={{
                  text: "인증번호 발송",
                  disabled: phone === authedPhone,
                  onClick: sendCode,
                }}
              />
              <p className="alert-desc" style={{ height: "24px" }}>
                {phoneAlertDesc}
              </p>

              <label>인증번호</label>
              <InputWithButton
                inputProps={{
                  type: "text",
                  placeholder: "인증번호 4자리",
                  maxLength: phone !== authedPhone ? 4 : undefined,
                  value:
                    !phone.length || phone !== authedPhone
                      ? authCode
                      : "인증이 완료되었습니다.",
                  onChange: (e) => {
                    setAuthCode(e.target.value);
                  },
                }}
                buttonProps={{
                  text: "인증하기",
                  disabled: !authingPhone || authCode.length !== 4,
                  onClick: confirmCode,
                }}
              />
              <p className="alert-desc" style={{ height: "60px" }}>
                {codeAlertDesc}
              </p>
            </>
          ) : (
            <>
              <label>변경할 비밀번호</label>
              <div
                className="flex-row-center-center"
                style={{
                  width: "100%",
                  position: "relative",
                  marginBottom: "14px",
                }}
              >
                <input
                  type={changePwSee ? "text" : "password"}
                  value={changePw}
                  placeholder="영문, 특수문자 포함 4~20자"
                  onChange={(e) => setChangePw(e.target.value)}
                  style={{ paddingRight: "32px" }}
                />
                {changePw.length > 0 && (
                  <img
                    src={!changePwSee ? iconEyeOff : iconEyeOn}
                    onClick={() => setChangePwSee((prev) => !prev)}
                    style={{
                      width: "24px",
                      height: "24px",
                      position: "absolute",
                      right: "8px",
                      cursor: "pointer",
                    }}
                  />
                )}
              </div>

              <label>변경할 비밀번호 확인</label>
              <div
                className="flex-row-center-center"
                style={{
                  width: "100%",
                  position: "relative",
                }}
              >
                <input
                  type={changePwReSee ? "text" : "password"}
                  value={changePwRe}
                  placeholder="영문, 특수문자 포함 4~20자"
                  onChange={(e) => setChangePwRe(e.target.value)}
                  style={{ paddingRight: "32px" }}
                />
                {changePwRe.length > 0 && (
                  <img
                    src={!changePwReSee ? iconEyeOff : iconEyeOn}
                    onClick={() => setChangePwReSee((prev) => !prev)}
                    style={{
                      width: "24px",
                      height: "24px",
                      position: "absolute",
                      right: "8px",
                      cursor: "pointer",
                    }}
                  />
                )}
              </div>
              <p className="alert-desc" style={{ height: "60px" }}>
                {alertDesc}
              </p>
            </>
          )}
        </div>

        <BaseButton
          text="비밀번호 변경"
          onClick={() => {
            if (phase === "auth") {
              setPhase("change");
              return;
            }

            find();
          }}
          disabled={
            phase === "auth"
              ? !authedPhone.length || !name.length
              : changePw.length <= 3 || changePwRe.length <= 3 || !!alertDesc
          }
        />
      </div>
    </ModalBaseYellow>
  );
};

export default ModalFindPw;
