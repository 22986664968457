import { useRecoilState, useSetRecoilState } from "recoil";
import { useCallback, useEffect, useState } from "react";
import {
  atom__changePwModalInfo,
  atom__successModalInfo,
} from "../../lib/recoil/modal.atom";
import BaseButton from "../Button/Base";
import SearchInputWithoutAttr from "../SearchInputWithoutAttr";
import { api_officeSearch } from "../../api/office";
import ModalBaseYellow from "./BaseYellow";
import iconEyeOn from "../../assets/eye_on.png";
import iconEyeOff from "../../assets/eye_off.png";
import { api_changePw } from "../../api/auth";

const ModalChangePw = () => {
  const [r__modalInfo, r__setModalInfo] = useRecoilState(
    atom__changePwModalInfo
  );

  const r__setSuccessModalInfo = useSetRecoilState(atom__successModalInfo);

  const [prevPw, setPrevPw] = useState("");
  const [changePw, setChangePw] = useState("");
  const [changePwRe, setChangePwRe] = useState("");

  const [prevPwSee, setPrevPwSee] = useState(false);
  const [changePwSee, setChangePwSee] = useState(false);
  const [changePwReSee, setChangePwReSee] = useState(false);

  const [alertDesc, setAlertDesc] = useState("");

  useEffect(() => {
    if (!r__modalInfo) {
      setPrevPw("");
      setChangePw("");
      setChangePwRe("");
      setPrevPwSee(false);
      setChangePwSee(false);
      setChangePwReSee(false);
      setAlertDesc("");
      return;
    }
  }, [r__modalInfo]);

  // alert description 지정
  useEffect(() => {
    if (!changePw && !changePwRe) return setAlertDesc("");

    setAlertDesc(
      changePw === changePwRe ? "" : "*비밀번호가 일치하지 않습니다."
    );
  }, [changePw, changePwRe]);

  const close = useCallback(() => {
    r__setModalInfo(null);
  }, []);

  const change = async () => {
    const res = await api_changePw({
      prevPw,
      pw: changePw,
      pwRe: changePwRe,
    });
    if (!res) return;

    close();
    r__setSuccessModalInfo({ desc: ["비밀번호가 변경되었습니다."] });
    r__modalInfo?.onChange();
  };

  return (
    <ModalBaseYellow
      visible={!!r__modalInfo}
      onClickOuter={close}
      title="비밀번호 변경"
    >
      <div
        className="flex-col-between-center"
        style={{
          padding: "20px",
          width: "100%",
          flex: 1,
        }}
      >
        <div style={{ width: "100%" }}>
          <label>기존 비밀번호</label>
          <div
            className="flex-row-center-center"
            style={{
              width: "100%",
              position: "relative",
              marginBottom: "14px",
            }}
          >
            <input
              type={prevPwSee ? "text" : "password"}
              value={prevPw}
              placeholder="영문, 특수문자 포함 4~20자"
              onChange={(e) => setPrevPw(e.target.value)}
              style={{ paddingRight: "32px" }}
            />
            {prevPw.length > 0 && (
              <img
                src={!prevPwSee ? iconEyeOff : iconEyeOn}
                onClick={() => setPrevPwSee((prev) => !prev)}
                style={{
                  width: "24px",
                  height: "24px",
                  position: "absolute",
                  right: "8px",
                  cursor: "pointer",
                }}
              />
            )}
          </div>

          <label>변경할 비밀번호</label>
          <div
            className="flex-row-center-center"
            style={{
              width: "100%",
              position: "relative",
              marginBottom: "14px",
            }}
          >
            <input
              type={changePwSee ? "text" : "password"}
              value={changePw}
              placeholder="영문, 특수문자 포함 4~20자"
              onChange={(e) => setChangePw(e.target.value)}
              style={{ paddingRight: "32px" }}
            />
            {changePw.length > 0 && (
              <img
                src={!changePwSee ? iconEyeOff : iconEyeOn}
                onClick={() => setChangePwSee((prev) => !prev)}
                style={{
                  width: "24px",
                  height: "24px",
                  position: "absolute",
                  right: "8px",
                  cursor: "pointer",
                }}
              />
            )}
          </div>

          <label>변경할 비밀번호 확인</label>
          <div
            className="flex-row-center-center"
            style={{
              width: "100%",
              position: "relative",
            }}
          >
            <input
              type={changePwReSee ? "text" : "password"}
              value={changePwRe}
              placeholder="영문, 특수문자 포함 4~20자"
              onChange={(e) => setChangePwRe(e.target.value)}
              style={{ paddingRight: "32px" }}
            />
            {changePwRe.length > 0 && (
              <img
                src={!changePwReSee ? iconEyeOff : iconEyeOn}
                onClick={() => setChangePwReSee((prev) => !prev)}
                style={{
                  width: "24px",
                  height: "24px",
                  position: "absolute",
                  right: "8px",
                  cursor: "pointer",
                }}
              />
            )}
          </div>
          <p className="alert-desc" style={{ height: "60px" }}>
            {alertDesc}
          </p>
        </div>

        <BaseButton
          text="변경"
          onClick={change}
          disabled={
            !(
              prevPw.length > 3 &&
              changePw.length > 3 &&
              changePwRe.length > 3 &&
              !alertDesc
            )
          }
        />
      </div>
    </ModalBaseYellow>
  );
};

export default ModalChangePw;
