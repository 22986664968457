import { TNews } from "../lib/types/news.type";
import { TNotice, TNoticeListItem } from "../lib/types/notice.type";
import axios from "./axios";

export const api_noticeList = async (from: number, limit: number) => {
  const res = await axios.get("/notice", {
    params: {
      from,
      limit,
      needTotalCount: from === 0 ? "y" : "n",
    },
  });
  if (!res) return;
  return res.data?.data as { list: TNoticeListItem[]; totalCount?: number };
};

export const api_noticeDetail = async (id: string) => {
  const res = await axios.get("/notice/" + id);
  if (!res) return;
  return res.data?.data as { notice: TNotice; isLastNotice: boolean };
};

export const api_noticeLast = async () => {
  const res = await axios.get("/notice/last");
  if (!res) return;
  return res.data?.data as { lastNoticeId: string };
};
