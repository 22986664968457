import { useRecoilState, useSetRecoilState } from "recoil";
import ModalBase from "./Base";
import {
  atom__alertModalInfo,
  atom__confirmModalInfo,
  atom__findIdPwModalInfo,
  atom__loginModalInfo,
  atom__notificationModalInfo,
  atom__simpleAlertModalInfo,
} from "../../lib/recoil/modal.atom";
import { useCallback, useEffect, useState } from "react";
import iconLogoFull from "../../assets/logo-full.png";
import BaseButton from "../Button/Base";
import { Link, useNavigate } from "react-router-dom";
import { api_login, api_loginCheck } from "../../api/auth";
import { atom__me } from "../../lib/recoil/common.atom";
import { printFormattedDate } from "../../lib/util";

export const LoginModal = () => {
  const navigate = useNavigate();
  const [r__modalInfo, r__setModalInfo] = useRecoilState(atom__loginModalInfo);
  const r__setAlertModalInfo = useSetRecoilState(atom__alertModalInfo);
  const r__setConfirmModalInfo = useSetRecoilState(atom__confirmModalInfo);
  const r__setSimpleAlertModalInfo = useSetRecoilState(
    atom__simpleAlertModalInfo
  );
  const r__setFindIdPwModalInfo = useSetRecoilState(atom__findIdPwModalInfo);
  const r__setMe = useSetRecoilState(atom__me);
  const r__setNotificationModalInfo = useSetRecoilState(
    atom__notificationModalInfo
  );

  const [uid, setUid] = useState("");
  const [pw, setPw] = useState("");

  useEffect(() => {
    if (!r__modalInfo) {
      setUid("");
      setPw("");
      return;
    }
  }, [r__modalInfo]);

  const close = useCallback(() => {
    r__setModalInfo(null);
  }, []);

  const beforeLogin = async () => {
    const checkRes = await api_loginCheck({ uid, pw });
    if (!checkRes) return;
    if (!checkRes.success) {
      switch (checkRes.err.code) {
        // 일치ㄴㄴ
        case "11000":
          r__setSimpleAlertModalInfo({ desc: ["존재하지 않는 계정입니다."] });
          break;
        // 중복로그인
        case "11001":
          r__setConfirmModalInfo({
            title: "로그인 알림",
            desc: [
              "새로운 환경에서의 로그인이 감지되었습니다.",
              "이전 환경의 로그인은 모두 로그아웃됩니다.",
            ],
            onClickBtnLeft: () => {
              r__setConfirmModalInfo(null);
            },
            onClickBtnRight: () => {
              r__setConfirmModalInfo(null);
              login();
            },
            btnRightText: "로그인",
          });
          break;
        // 심사중
        case "11002":
        // 이용제한됨
        case "11004":
          r__setAlertModalInfo({
            title: "사용제한",
            desc: checkRes.err.msg.split("\n"),
            theme: "info",
          });
          break;
      }

      return;
    }

    login();
  };

  const login = async () => {
    const res = await api_login({ uid, pw });
    if (!res) return;
    if (!res.success)
      return r__setSimpleAlertModalInfo({ desc: [res.err.msg] });

    const { accessToken, me } = res.data;
    r__setMe(me);

    // 알림팝업 표시: unreadNotification이 true일 때 + notificationStopDate가 null이거나 오늘날짜보다 이전일때
    if (me.unreadNotification && !!me.activeMembershipId) {
      if (!me.notificationStopDate) r__setNotificationModalInfo(true);
      else {
        const strToday = printFormattedDate(new Date(), { seperator: "-" });
        if (me.notificationStopDate < strToday)
          r__setNotificationModalInfo(true);
      }
    }

    if (!me.activeMembershipId)
      r__setConfirmModalInfo({
        theme: "info",
        title: "멤버십 만료",
        desc: [
          "기존 멤버십이 만료되었습니다",
          "멤버십 갱신 후 서비스 사용이 가능합니다.",
        ],
        btnRightText: "멤버십 갱신",
        onClickBtnRight: () => {
          navigate("/my/membership");
        },
      });

    r__modalInfo?.onLogin?.();
    close();
  };

  return (
    <ModalBase
      visible={!!r__modalInfo}
      onClickOuter={close}
      innerStyle={{ width: "480px" }}
    >
      <img src={iconLogoFull} style={{ width: "100%", marginBottom: "40px" }} />

      <label htmlFor="uid">아이디</label>
      <input
        id="uid"
        type="text"
        placeholder="아이디 입력"
        style={{ marginBottom: "20px" }}
        value={uid}
        onChange={(e) => {
          setUid(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.code === "Enter") beforeLogin();
        }}
      />
      <label htmlFor="pw">비밀번호</label>
      <input
        id="pw"
        type="password"
        placeholder="비밀번호 입력"
        value={pw}
        onChange={(e) => {
          setPw(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.code === "Enter") beforeLogin();
        }}
      />

      <BaseButton
        text="로그인"
        onClick={beforeLogin}
        style={{ marginTop: "60px", marginBottom: "20px" }}
      />
      <div className="flex-row-between-center" style={{ marginBottom: "20px" }}>
        <p className="modal-descs">아직 회원이 아니신가요?</p>
        <Link
          to="/register/step1"
          className="text-small"
          style={{ color: "var(--yellow-dark)" }}
          onClick={close}
        >
          회원가입
        </Link>
      </div>

      <div
        className="flex-row"
        style={{ justifyContent: "flex-end" }}
        onClick={() => {
          close();
          r__setFindIdPwModalInfo(true);
        }}
      >
        <p
          className="text-small"
          style={{
            textDecoration: "underline",
            color: "var(--yellow-dark)",
            cursor: "pointer",
          }}
        >
          아이디 / 비밀번호 찾기
        </p>
      </div>
    </ModalBase>
  );
};
