import iconSearch from "../assets/search.png";

interface IProps {
  onClick: () => void;
  label: string;
  placeholder: string;
  style?: React.CSSProperties;
}

const FakeSearchInput = (props: IProps) => {
  return (
    <div
      className="flex-row-start-center file-input-container"
      style={props.style}
      onClick={props.onClick}
    >
      <div
        className="flex-row-start-center"
        style={{
          color: !!props.label ? "#000" : "var(--border-gray)",
          flex: 1,
        }}
      >
        {props.label || props.placeholder}
      </div>

      <div style={{ width: "20px", height: "20px" }}>
        <img src={iconSearch} style={{ width: "100%", height: "100%" }} />
      </div>
    </div>
  );
};

export default FakeSearchInput;
