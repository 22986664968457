import { useRecoilState, useRecoilValue } from "recoil";
import { atom__globalPostFilter, atom__me } from "../../lib/recoil/common.atom";
import SelectSmall from "../SelectSmall";
import { useEffect, useMemo, useRef, useState } from "react";
import { api_districtList, api_listOnMap } from "../../api/apart";
import useIsMobile from "../../lib/hooks/useIsMobile";

interface IProps {
  onChangeAddress: (lat: number, lng: number, zoom: number) => void;
}
const GlobalPostFilter = (props: IProps) => {
  const isMobile = useIsMobile();

  const [r__filter, r__setFilter] = useRecoilState(atom__globalPostFilter);
  const r__me = useRecoilValue(atom__me);

  const [as2Items, setAs2Items] = useState([{ label: "구", value: "x" }]);
  const [as3Items, setAs3Items] = useState([{ label: "동", value: "x" }]);

  // 각 셀렉트박스의 option들. 구/동의 option들은 서버에서 받아오기 때문에 따로 state로 관리.
  const selectItems = useMemo(() => {
    return {
      apartKind: [
        { label: "유형", value: "x" },
        { label: "아파트", value: "아파트" },
        // { label: "분양", value: "분양" },
      ],
      postKind: [
        { label: "전체", value: "x" },
        { label: "매매", value: "매매" },
        { label: "전월세", value: "전월세" },
      ],
      houseCount: [
        { label: "세대 수", value: "x-x" },
        { label: "~100세대", value: "x-100" },
        { label: "~500세대", value: "x-500" },
        { label: "~1000세대", value: "x-1000" },
        { label: "~2000세대", value: "x-2000" },
        { label: "~3000세대", value: "x-3000" },
        { label: "3000세대~", value: "3000-x" },
      ],
      enterAge: [
        { label: "입주년차", value: "x-x" },
        { label: "~3년", value: "x-3" },
        { label: "~5년", value: "x-5" },
        { label: "~10년", value: "x-10" },
        { label: "~15년", value: "x-15" },
        { label: "~25년", value: "x-25" },
        { label: "25년~", value: "25-x" },
      ],
      amount: [
        { label: "가격", value: "x-x" },
        { label: "~1억", value: "x-1" },
        { label: "~3억", value: "x-3" },
        { label: "~5억", value: "x-5" },
        { label: "~10억", value: "x-10" },
        { label: "~15억", value: "x-15" },
        { label: "~20억", value: "x-20" },
        { label: "~30억", value: "x-30" },
        { label: "30억~", value: "30-x" },
      ],
      size: [
        { label: "평형", value: "x-x" },
        { label: "10평대", value: "10-20" },
        { label: "20평대", value: "20-30" },
        { label: "30평대", value: "30-40" },
        { label: "40평대", value: "40-50" },
        { label: "50평대", value: "50-60" },
        { label: "60평대~", value: "60-x" },
      ],
    };
  }, []);

  const refAs3Items = useRef<
    Record<string, { label: string; value: string }[]>
  >({ x: [{ label: "동", value: "x" }] });

  const refAs2Latlng = useRef<Record<string, [number, number]>>({});
  const refAs3Latlng = useRef<Record<string, [number, number]>>({});

  useEffect(() => {
    if (!r__me) return;
    // 구, 동들 데이터 fetch

    api_districtList().then((res) => {
      if (!res) return;
      setAs2Items([
        { label: "구", value: "x" },
        ...res.as2List.map((district) => ({
          label: district.name,
          value: district.id,
        })),
      ]);

      refAs3Items.current = {
        x: [{ label: "동", value: "x" }],
        ...res.as3List.reduce(
          (acc: Record<string, { label: string; value: string }[]>, cur) => {
            if (!acc[cur.parentDistrictId])
              acc[cur.parentDistrictId] = [{ label: "동", value: "x" }];
            acc[cur.parentDistrictId].push({ label: cur.name, value: cur.id });
            return acc;
          },
          {}
        ),
      };

      refAs2Latlng.current = res.as2List.reduce<
        Record<string, [number, number]>
      >((acc, cur) => {
        acc[cur.id] = [cur.centerLat, cur.centerLng];
        return acc;
      }, {});
      refAs3Latlng.current = res.as3List.reduce<
        Record<string, [number, number]>
      >((acc, cur) => {
        acc[cur.id] = [cur.centerLat, cur.centerLng];
        return acc;
      }, {});
    });
  }, [r__me]);

  return (
    <div
      className="flex-row-center-center global-post-filter"
      style={
        isMobile
          ? {
              flexWrap: "nowrap",
              overflowX: "auto",
              justifyContent: "flex-start",
              padding: "10px",
            }
          : {}
      }
    >
      <SelectSmall
        items={as2Items}
        value={r__filter.address2}
        onChange={(v) => {
          const changingLatlng = refAs2Latlng.current[v];
          if (changingLatlng)
            props.onChangeAddress(changingLatlng[0], changingLatlng[1], 14);
          // 구 바뀌면 동목록도 변경
          setAs3Items(refAs3Items.current[v]);
          r__setFilter((prev) => ({
            ...prev,
            address2: v,
            address3: "x",
          }));
        }}
      />
      <SelectSmall
        items={as3Items}
        value={r__filter.address3}
        onChange={(v) => {
          const changingLatlng = refAs3Latlng.current[v];
          if (changingLatlng)
            props.onChangeAddress(changingLatlng[0], changingLatlng[1], 16);

          r__setFilter((prev) => ({ ...prev, address3: v }));
        }}
      />
      <SelectSmall
        items={selectItems.apartKind}
        value={r__filter.apartKind}
        onChange={(v) => r__setFilter((prev) => ({ ...prev, apartKind: v }))}
      />
      <SelectSmall
        items={selectItems.postKind}
        value={r__filter.postKind}
        onChange={(v) => r__setFilter((prev) => ({ ...prev, postKind: v }))}
      />
      <SelectSmall
        items={selectItems.houseCount}
        value={r__filter.houseCount}
        onChange={(v) => r__setFilter((prev) => ({ ...prev, houseCount: v }))}
      />
      <SelectSmall
        items={selectItems.enterAge}
        value={r__filter.enterAge}
        onChange={(v) => r__setFilter((prev) => ({ ...prev, enterAge: v }))}
      />
      <SelectSmall
        items={selectItems.amount}
        value={r__filter.amount}
        onChange={(v) => r__setFilter((prev) => ({ ...prev, amount: v }))}
      />
      <SelectSmall
        items={selectItems.size}
        value={r__filter.size}
        onChange={(v) => r__setFilter((prev) => ({ ...prev, size: v }))}
      />
    </div>
  );
};

export default GlobalPostFilter;
