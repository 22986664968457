import { TMembership, TUserMembership } from "../lib/types/membership.type";
import { TTermKind } from "../lib/types/term.type";
import axios, { TFetchRes } from "./axios";

export const api_membershipItem = async () => {
  const res = await axios.get("/membership");
  return res.data?.data as { list: TMembership[] };
};

export const api_membershipMine = async () => {
  const res = await axios.get("/user-membership/mine");
  return res.data?.data as {
    mine: TUserMembership | null;
    membershipItems: TMembership[];
  };
};

export const api_membershipPurchase = async (membershipId: string) => {
  const res = await axios.post("/user-membership/buy", { membershipId });
  if (!res) return;
  return res.data as TFetchRes;
};

export const api_membershipRefund = async (userMembershipId: string) => {
  const res = await axios.post(
    `/user-membership/${userMembershipId}/refund-apply`
  );
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data?.data as {};
};
