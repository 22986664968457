import React from "react";
import { TBadge } from "../../lib/types/badge.type";
import iconMedalGold from "../../assets/medal_gold.png";
import iconMedalBronze from "../../assets/medal_bronze.png";
import iconMedalSilver from "../../assets/medal_silver.png";

interface IProps {
  badge: TBadge;
  apartName: string;
  style?: React.CSSProperties;
}
const PurchasingBadge = ({ badge, apartName, style }: IProps) => {
  return (
    <div
      style={{
        border: "1px solid var(--yellow-dark)",
        borderRadius: "8px",
        padding: "17px 20px",
        minWidth: "260px",
        ...(style ?? {}),
      }}
    >
      <div
        className="flex-row-start-center"
        style={{
          paddingBottom: "11px",
          marginBottom: "11px",
          borderBottom: "1px solid var(--border-gray)",
        }}
      >
        <img
          src={
            badge.worth === 50
              ? iconMedalGold
              : badge.worth === 30
              ? iconMedalSilver
              : iconMedalBronze
          }
          style={{ width: "32px", height: "32px", marginRight: "18px" }}
        />
        <p className="text-small bold">{badge.name}</p>
      </div>

      <p className="text-small bold" style={{ marginBottom: "12px" }}>
        {badge.benefit}
      </p>
      <p className="text-small bold" style={{ marginBottom: "20px" }}>
        월 {Math.floor(badge.amountPerMonth / 10000)}만원
      </p>
      <p className="text-small bold" style={{ marginBottom: "20px" }}>
        {apartName}
      </p>
    </div>
  );
};

export default PurchasingBadge;
