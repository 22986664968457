import { TApart } from "./apart.type";
import { TDefaultEntity } from "./common.type";
import { TOffice } from "./office.type";

export type TBadge = TDefaultEntity & {
  name: string;
  worth: number;
  days: number;
  benefit: string;
  amountPerMonth: number;
  needs: string;
};

export enum EOfficeBadgeStatus {
  /** 승인대기 */
  PENDING = "pending",
  /** 승인되어 효력을 발휘하는중 */
  ACCEPT = "accept",
  /** 거절됨 */
  REJECT = "reject",
  /** 만료됨 */
  EXPIRED = "expired",
}
export type TOfficeBadge = TDefaultEntity & {
  officeId: string;
  badgeId: string;
  apartId: string;
  status: EOfficeBadgeStatus;
  startDate: string | null;
  endDate: string | null;

  office: TOffice;
  badge: TBadge;
  apart: TApart;
};
