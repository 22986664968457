import { api_fileUploadCommit, api_fileUploadPrepare } from "../api/file";

export const uploadFile = async (
  file: File
): Promise<{ fileId: string; url: string } | undefined> => {
  const prepareRes = await api_fileUploadPrepare(file.name);
  if (!prepareRes) return;

  const { id: preparedFileId, signedUrl, url } = prepareRes.preparedFiles[0];
  // s3
  const resS3 = await fetch(
    new Request(signedUrl, {
      method: "PUT",
      body: file,
      headers: new Headers({
        "Content-Type": file.type,
      }),
    })
  );
  if (resS3.status !== 200) {
    alert("죄송합니다. 잠시 후 다시 이용해 주세요.");
    return;
  }

  await api_fileUploadCommit(preparedFileId);

  return { fileId: preparedFileId, url };
};
