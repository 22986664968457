import { TPostListItemWithStatus } from "../lib/types/post.type";
import {
  TEmployeeDetail,
  TEmployeeListItem,
  TUserDetailRes,
} from "../lib/types/user.type";
import axios from "./axios";

export const api_employeeList = async () => {
  const res = await axios.get("/user/employee");
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as { list: TEmployeeListItem[] };
};

export const api_employeeDetail = async (userId: string) => {
  const res = await axios.get("/user/employee/" + userId);
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as { employee: TEmployeeDetail };
};
export const api_employeePostList = async (params: {
  userId: string;
  withTradeIng: "y" | "n";
  withTradeDone: "y" | "n";
}) => {
  const res = await axios.get("/post/employee-post", { params });
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as { list: TPostListItemWithStatus[] };
};
export const api_userDetail = async (userId: string, apartId: string) => {
  const res = await axios.get(`/user/${userId}`, { params: { apartId } });
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as TUserDetailRes;
};

export const api_complaint = async (payload: {
  targetUserId: string;
  reason: string;
  content: string;
}) => {
  const res = await axios.post(`/complaint`, payload);
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as {};
};

export const api_stopNotification = async () => {
  const res = await axios.post(`/user/stop-notification`);
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as {};
};
