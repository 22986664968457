import { TNews } from "../lib/types/news.type";
import axios from "./axios";

export const api_newsList = async (
  from: number,
  limit: number,
  region: "전국" | "수원"
) => {
  const res = await axios.get("/news", {
    params: {
      from,
      limit,
      region,
      needTotalCount: from === 0 ? "y" : "n",
    },
  });
  return res.data?.data as { list: TNews[] };
};
