import { useNavigate } from "react-router-dom";
import BaseButton from "../../components/Button/Base";
import { useSetRecoilState } from "recoil";
import useIsMobile from "../../lib/hooks/useIsMobile";
import { useEffect, useState } from "react";
import Select from "../../components/Select";
import { atom__loginModalInfo } from "../../lib/recoil/modal.atom";

const PostStep0Page = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const r__setLoginModalInfo = useSetRecoilState(atom__loginModalInfo);

  const [ownerName, setOwnerName] = useState("");
  const [ownerPhone, setOwnerPhone] = useState("");

  // 자동하이픈
  useEffect(() => {
    if (ownerPhone.length === 10) {
      setOwnerPhone(ownerPhone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"));
    }
    if (ownerPhone.length === 13) {
      setOwnerPhone(
        ownerPhone
          .replace(/-/g, "")
          .replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3")
      );
    }
  }, [ownerPhone]);

  return (
    <div
      className="post-register-page-wrapper"
      style={
        isMobile
          ? {
              paddingLeft: "20px",
              paddingRight: "20px",
            }
          : {}
      }
    >
      {!isMobile && <h2>매물 등록 요청하기</h2>}
      <div
        className="flex-col-center-center"
        style={{ width: "344px", flex: 1, alignSelf: "center" }}
      >
        <div style={{ width: "100%" }}>
          <label>유형</label>
          <Select
            items={[
              { label: "소유주", value: "소유주" },
              { label: "중개인", value: "중개인" },
            ]}
            value={"소유주"}
            onChange={(v) => {
              if (v === "중개인") {
                r__setLoginModalInfo({
                  onLogin: () => {
                    window.location.replace("/post/step1");
                  },
                });
                return;
              }
            }}
            style={{ marginBottom: "20px" }}
          />

          <label>소유주 이름</label>
          <input
            type="text"
            placeholder="홍길동"
            value={ownerName}
            onChange={(e) => setOwnerName(e.target.value)}
            style={{ marginBottom: "20px" }}
          />

          <label>소유주 전화번호</label>
          <div className="flex-between-center" style={{ gap: "20px" }}>
            <input
              type="text"
              value={ownerPhone}
              onChange={(e) => setOwnerPhone(e.target.value)}
              placeholder="'-' 없이 숫자만 입력"
            />
          </div>
        </div>

        <BaseButton
          text="다음"
          disabled={ownerName.length < 3 || ownerPhone.length !== 13}
          onClick={() => {
            navigate("/post/step1", { state: { ownerName, ownerPhone } });
          }}
          style={{ width: "100%", marginTop: "100px" }}
        />
      </div>
    </div>
  );
};

export default PostStep0Page;
