import { useLocation, useNavigate } from "react-router-dom";
import BaseButton from "../../components/Button/Base";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { atom__me } from "../../lib/recoil/common.atom";
import useIsMobile from "../../lib/hooks/useIsMobile";
import { useRef, useState } from "react";
import {
  atom__alertModalInfo,
  atom__loading,
  atom__successModalInfo,
} from "../../lib/recoil/modal.atom";
import BaseButtonOutline from "../../components/Button/Outline";
import { printFileSize } from "../../lib/util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { api_postRegister, api_uploadPostImages } from "../../api/post";

type TSelectedFile = { file: File; url: string; width: number; height: number };

const PostStep4Page = () => {
  const prevState = useLocation().state;
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const r__setLoading = useSetRecoilState(atom__loading);
  const r__me = useRecoilValue(atom__me);
  const r__setAlertModalInfo = useSetRecoilState(atom__alertModalInfo);
  const r__setSuccessModalInfo = useSetRecoilState(atom__successModalInfo);

  const [files, setFiles] = useState<TSelectedFile[]>([]);

  const refFileInput = useRef<HTMLInputElement>(null);

  const removeFile = (idx: number) => {
    setFiles((prev) => {
      const copied = prev.slice();
      copied.splice(idx, 1);
      return copied;
    });
  };

  const _getFileDimension = (file: File) =>
    new Promise<{ width: number; height: number }>((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = function (e) {
        if (!e.target) return reject(null);

        var img = new Image();
        img.src = e.target.result as string;

        img.onload = function () {
          // @ts-ignore
          var width = this.width;
          // @ts-ignore
          var height = this.height;
          return resolve({ width, height });
        };
      };
    });

  const register = async () => {
    if (r__me?.isSale) return alert("영업직원 계정은 제한되는 기능입니다.");

    r__setLoading(true);

    /**
     * NOTE:
     * 1. 선택한 파일이 있다면, 유효한 파일들인지 검사
     * 2. 매물 먼저 등록
     * 3. 매물사진 업로드
     */

    // 1
    if (files.length) {
      if (files.length > 20)
        return r__setAlertModalInfo({
          title: "파일 개수 초과",
          desc: ["최대 20개의 파일만 업로드할 수 있습니다."],
        });
      const bigFile = files.find((f) => f.file.size / 1024 / 1024 > 3);
      if (bigFile)
        return r__setAlertModalInfo({
          title: "파일 크기 초과",
          desc: ["파일 크기는 최대 3MB입니다."],
        });

      // 이미지 사이즈 w/h 검사
      const smallFile = files.find((f) => f.width < 480 || f.height < 260);
      if (smallFile)
        return r__setAlertModalInfo({
          title: "사진 크기 미달",
          desc: ["사진은 가로480, 세로260보다 작을 수 없습니다."],
        });
    }

    // 2
    const payload = prevState;
    const res = await api_postRegister(payload);
    if (!res) return;

    // 3
    if (files.length) {
      const uploadRes = await api_uploadPostImages(
        res.id,
        files.map((f) => f.file)
      );
      if (!uploadRes) return;
    }

    r__setSuccessModalInfo({
      desc: ["매물 등록 요청이 정상적으로 접수되었습니다."],
      onClickBtn: () => {
        window.location.replace("/map");
      },
    });

    return;
  };

  return (
    <div
      className="post-register-page-wrapper"
      style={
        isMobile
          ? {
              paddingLeft: "20px",
              paddingRight: "20px",
            }
          : {}
      }
    >
      {!isMobile && <h2>매물 등록 요청하기</h2>}
      <div
        className="flex-col-center-center"
        style={{ width: "344px", flex: 1, alignSelf: "center" }}
      >
        <div style={{ width: "100%" }}>
          <label>매물 사진 (선택)</label>

          <details>
            <summary style={{ margin: "20px 0" }}>꼭 확인하세요!</summary>
            <p
              className="text-small bold"
              style={{ color: "var(--tomato)", marginBottom: "10px" }}
            >
              * 최대 20장까지 업로드 가능합니다.
            </p>
            <p
              className="text-small bold"
              style={{ color: "var(--tomato)", marginBottom: "10px" }}
            >
              * 각 사진은 3MB보다 작아야합니다.
            </p>
            <p
              className="text-small bold"
              style={{ color: "var(--tomato)", marginBottom: "10px" }}
            >
              * 사진은 가로480 : 세로260의 비율로 표시됩니다.
            </p>

            <p
              className="text-small bold"
              style={{ color: "var(--tomato)", marginBottom: "10px" }}
            >
              * 업로드할 수 있는 파일 확장자는 PNG, JPG, JPEG입니다.
            </p>
          </details>

          {/* 파일선택 */}
          <div
            style={{
              border: "1px dotted var(--border-gray)",
              borderRadius: "8px",
              width: "100%",
              height: "48px",
              position: "relative",
              overflow: "hidden",
              marginBottom: "24px",
              cursor: "pointer",
            }}
            onClick={() => {
              refFileInput.current?.click();
            }}
          >
            <input
              type="file"
              accept="image/png,image/jpg,image/jpeg"
              multiple
              maxLength={20}
              ref={refFileInput}
              onChange={async (e) => {
                if (!e.target.files?.length) return;

                const addingFiles = Array.from(e.target.files);
                if (files.length + addingFiles.length > 20) {
                  e.target.value = "";
                  return alert("파일은 최대 20장까지만 업로드할 수 있습니다.");
                }

                const addingFilesWithInfo: TSelectedFile[] = [];
                for (const f of addingFiles) {
                  const url = URL.createObjectURL(f);
                  const dimension = await _getFileDimension(f);
                  addingFilesWithInfo.push({
                    file: f,
                    url,
                    width: dimension.width,
                    height: dimension.height,
                  });
                }

                setFiles((prev) => [...prev, ...addingFilesWithInfo]);

                e.target.value = "";
              }}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                width: "10px",
                height: "10px",
              }}
            />
            {/* 가림막 (인풋 안보이게) */}
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                backgroundColor: "#fff",
              }}
            />

            <div
              className="flex-row-between-center text-small bold"
              style={{
                position: "relative",
                width: "100%",
                height: "100%",
                padding: "0 17px",
              }}
            >
              <span style={{ color: "var(--yellow-dark)" }}>파일 선택하기</span>
              <FontAwesomeIcon
                icon={faPlus}
                color="var(--yellow-dark)"
                style={{ width: "24px", height: "24px" }}
              />
            </div>
          </div>

          {/* 선택한 파일들 */}
          {files.map((f, i) => {
            const isTooBig = f.file.size / 1024 / 1024 > 3;
            const isTooSmall = f.width < 480 || f.height < 260;

            return (
              <div
                key={f.url}
                className="flex-row-start-center"
                style={{
                  width: "100%",
                  height: "48px",
                  borderRadius: "8px",
                  overflow: "hidden",
                  border: `1px solid var(${
                    isTooBig || isTooSmall ? "--tomato" : "--border-gray"
                  })`,
                  marginBottom: "24px",
                }}
              >
                <img
                  src={f.url}
                  style={{ width: "48px", height: "48px", objectFit: "cover" }}
                />
                <div
                  className="flex-col"
                  style={{
                    justifyContent: "space-evenly",
                    alignItems: "flex-start",
                    flex: 1,
                    padding: "0 10px",
                  }}
                >
                  <span style={{ fontSize: "10px", marginBottom: "4px" }}>
                    {f.file.name}
                  </span>
                  <span
                    style={{
                      fontSize: "10px",
                      color: "var(--border-gray)",
                    }}
                  >
                    <span
                      style={{
                        marginRight: "8px",
                        ...(isTooBig ? { color: "var(--tomato)" } : {}),
                      }}
                    >
                      {printFileSize(f.file.size)}
                    </span>
                    <span
                      style={{
                        ...(isTooSmall ? { color: "var(--tomato)" } : {}),
                      }}
                    >
                      {f.width} / {f.height}
                    </span>
                  </span>
                </div>
                <div
                  className="flex-row-center-center"
                  style={{ width: "48px", height: "48px", cursor: "pointer" }}
                  onClick={() => {
                    removeFile(i);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faTrash}
                    style={{
                      color: "var(--tomato)",
                      width: "12px",
                      height: "12px",
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>

        <div
          className="flex-row-between-center"
          style={{ gap: "20px", marginTop: "100px", width: "100%" }}
        >
          <BaseButtonOutline
            text="이전"
            onClick={() => navigate(-1)}
            style={{ flex: 1, fontSize: "16px" }}
          />
          <BaseButton
            text="등록 요청"
            onClick={() => {
              r__setLoading(true);
              register().finally(() => r__setLoading(false));
            }}
            style={{ flex: 1, fontWeight: 400, fontSize: "16px" }}
          />
        </div>
      </div>
    </div>
  );
};

export default PostStep4Page;
