import axios from "./axios";
import { TOfficeSearchResultItem } from "../lib/types/office.type";

export const api_officeSearch = async (keyword: string) => {
  const res = await axios.get("/office/search?keyword=" + keyword);
  return res.data?.data as { list: TOfficeSearchResultItem[] };
};

export const api_officeChange = async (payload: {
  officeId: string;
  changingImageUrlBusiness?: string;
  changingImageUrlOffice?: string;
  changingImageUrlCert?: string;
}) => {
  const res = await axios.post("/office/change", payload);
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data?.data as {};
};
