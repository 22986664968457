import { useLocation, useNavigate } from "react-router-dom";
import BaseButton from "../../components/Button/Base";
import { useSetRecoilState } from "recoil";
import useIsMobile from "../../lib/hooks/useIsMobile";
import { useEffect, useState } from "react";
import { atom__searchApartModalInfo } from "../../lib/recoil/modal.atom";
import Checkbox from "../../components/Checkbox";
import FakeSearchInput from "../../components/FakeSearchInput";
import BaseButtonOutline from "../../components/Button/Outline";

const PostStep1Page = () => {
  const prevState = useLocation().state;
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const r__setSearchApartModalInfo = useSetRecoilState(
    atom__searchApartModalInfo
  );

  const [selectedApart, setSelectedApart] = useState({ id: "", name: "" });
  const [areaType, setAreaType] = useState("");
  const [exclusiveArea, setExclusiveArea] = useState("");
  const [commonArea, setCommonArea] = useState("");
  const [dong, setDong] = useState("");
  const [ho, setHo] = useState("");
  const [isLiveCustomer, setIsLiveCustomer] = useState(false);
  const [customerPhone, setCustomerPhone] = useState("");

  // 자동하이픈
  useEffect(() => {
    if (customerPhone.length === 10) {
      setCustomerPhone(
        customerPhone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
      );
    }
    if (customerPhone.length === 13) {
      setCustomerPhone(
        customerPhone
          .replace(/-/g, "")
          .replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3")
      );
    }
  }, [customerPhone]);
  // 공실로 체크하면 세입자전화번호 초기화
  useEffect(() => {
    if (!isLiveCustomer) setCustomerPhone("");
  }, [isLiveCustomer]);

  return (
    <div
      className="post-register-page-wrapper"
      style={
        isMobile
          ? {
              paddingLeft: "20px",
              paddingRight: "20px",
            }
          : {}
      }
    >
      {!isMobile && (
        <h2 style={{ marginBottom: "40px" }}>매물 등록 요청하기</h2>
      )}
      <div
        className="flex-col-center-center"
        style={{ width: "344px", flex: 1, alignSelf: "center" }}
      >
        <div style={{ width: "100%" }}>
          <label>아파트</label>
          <FakeSearchInput
            label={selectedApart.name}
            placeholder="아파트 검색"
            onClick={() => {
              r__setSearchApartModalInfo({
                onSelect: (id, name) => {
                  setSelectedApart({ id, name });
                },
              });
            }}
            style={{
              marginBottom: "20px",
            }}
          />

          <div className="flex-row-between-center">
            <label>타입</label>
            <p className="alert-desc">영문 또는 숫자만 입력 가능</p>
          </div>
          <input
            type="text"
            placeholder="타입 입력 ex) 84A"
            value={areaType}
            onChange={(e) => setAreaType(e.target.value.toUpperCase())}
            style={{ marginBottom: "20px" }}
          />

          <div
            className="flex-row-between-center"
            style={{ gap: "20px", marginBottom: "20px" }}
          >
            <div style={{ flex: 1 }}>
              <label>전용면적 (제곱미터)</label>
              <input
                type="number"
                min={0}
                placeholder="23.5"
                value={exclusiveArea}
                onChange={(e) => {
                  setExclusiveArea(e.target.value);
                }}
                style={{ flex: 1 }}
              />
            </div>
            <div style={{ flex: 1 }}>
              <label>공용면적 (제곱미터)</label>
              <input
                type="number"
                min={0}
                placeholder="30.4"
                value={commonArea}
                onChange={(e) => {
                  setCommonArea(e.target.value);
                }}
                style={{ flex: 1 }}
              />
            </div>
          </div>

          <div className="flex-row-between-center">
            <label>동</label>
            <p className="alert-desc">
              최대 4개의 숫자 또는 2글자의 한글 입력 가능
            </p>
          </div>
          <input
            type="text"
            placeholder="'동' 제외하고 입력 ex) 203, 나"
            value={dong}
            onChange={(e) => setDong(e.target.value)}
            style={{ marginBottom: "20px" }}
          />

          <div className="flex-row-between-center">
            <label>호수</label>
            <p className="alert-desc">영문 또는 숫자만 입력 가능</p>
          </div>
          <input
            type="text"
            placeholder="'호' 제외하고 입력 ex) 1203"
            value={ho}
            onChange={(e) => setHo(e.target.value)}
            style={{ marginBottom: "20px" }}
          />

          <label>세입자 거주 여부</label>
          <div
            className="flex-row-start-center"
            style={{ marginBottom: "20px" }}
          >
            <Checkbox
              checked={!isLiveCustomer}
              onClick={() => setIsLiveCustomer(false)}
              label="공실"
              style={{ paddingRight: "20px" }}
            />
            <Checkbox
              checked={isLiveCustomer}
              onClick={() => setIsLiveCustomer(true)}
              label="거주중"
            />
          </div>

          {isLiveCustomer && (
            <>
              <label>세입자 전화번호</label>
              <div className="flex-between-center" style={{ gap: "20px" }}>
                <input
                  type="text"
                  placeholder="'-' 없이 숫자만 입력"
                  value={customerPhone}
                  onChange={(e) => setCustomerPhone(e.target.value)}
                />
              </div>
            </>
          )}
        </div>

        <div
          className="flex-row-between-center"
          style={{ gap: "20px", marginTop: "100px", width: "100%" }}
        >
          <BaseButtonOutline
            text="이전"
            onClick={() => navigate(-1)}
            style={{ flex: 1, fontSize: "16px" }}
          />
          <BaseButton
            text="다음 (1/4)"
            onClick={() => {
              const regAreaType = new RegExp(/^\d+[A-Z]$/);
              if (!regAreaType.test(areaType)) {
                return alert("올바른 형식의 타입을 입력해주세요. 예시) 84A");
              }
              navigate("/post/step2", {
                state: {
                  ...(prevState ?? {}), // {ownerName, ownerPhone} or {creatorUserId}
                  apartId: selectedApart.id,
                  areaType,
                  exclusiveArea: Number(exclusiveArea),
                  commonArea: Number(commonArea),
                  dong,
                  ho,
                  isLiveCustomer,
                  customerPhone: customerPhone || null,
                },
              });
            }}
            style={{ flex: 1, fontWeight: 400, fontSize: "16px" }}
          />
        </div>
      </div>
    </div>
  );
};

export default PostStep1Page;
