import { useCallback } from "react";
import ModalBase from "./Base";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  atom__alertModalInfo,
  atom__confirmModalInfo,
  atom__purchaseBadgeModalInfo,
} from "../../lib/recoil/modal.atom";
import BaseButton from "../Button/Base";
import BaseButtonOutline from "../Button/Outline";
import iconAlert from "../../assets/alert.png";
import iconInfo from "../../assets/info.png";
import PurchasingBadge from "../Badge/PurchasingBadge";
import { api_badgePurchase } from "../../api/badge";
import { atom__me } from "../../lib/recoil/common.atom";

const ModalPurchaseBadge = () => {
  const [r__modalInfo, r__setModalInfo] = useRecoilState(
    atom__purchaseBadgeModalInfo
  );
  const r__me = useRecoilValue(atom__me);

  const r__setAlertModalInfo = useSetRecoilState(atom__alertModalInfo);

  const close = useCallback(() => {
    r__setModalInfo(null);
  }, []);

  const purchase = async () => {
    if (!r__modalInfo) return;
    if (r__me?.isSale) {
      alert("영업직원 계정은 제한되는 기능입니다.");
      return;
    }

    const res = await api_badgePurchase(
      r__modalInfo.badge.id,
      r__modalInfo.apart.id
    );
    if (!res) return;
    // 실패
    if (!res.data.success)
      return r__setAlertModalInfo({
        title: "구매 실패",
        desc: (res.data?.err?.msg ?? "").split("\n"),
      });

    // 성공
    close();
    r__modalInfo.onSuccess();
  };

  if (!r__modalInfo) return null;

  return (
    <ModalBase visible={!!r__modalInfo} onClickOuter={close}>
      <div className="flex-col-center-center">
        <h2 style={{ marginBottom: "20px" }}>구매 신청</h2>
        <div style={{ marginBottom: "20px" }}>
          <p className="modal-desc">아래 상품에 대해 구매가 요청됩니다.</p>
          <p className="modal-desc">관리자 확인 후 문자안내 예정입니다.</p>
          <p className="modal-desc" style={{ color: "var(--tomato)" }}>
            *단지는 최초 지정 후 변경이 불가합니다.
          </p>
        </div>

        <PurchasingBadge
          badge={r__modalInfo.badge}
          apartName={r__modalInfo.apart.name}
          style={{ marginBottom: "40px" }}
        />

        <div className="flex-row-between-center">
          <BaseButtonOutline
            onClick={close}
            text={"취소"}
            style={{
              borderColor: "var(--border-gray)",
              color: "var(--border-gray)",
              marginRight: "20px",
              width: "163px",
            }}
          />
          <BaseButton
            onClick={purchase}
            text={"신청"}
            style={{
              flex: 1,
              width: "163px",
            }}
          />
        </div>
      </div>
    </ModalBase>
  );
};

export default ModalPurchaseBadge;
