import { useEffect, useState } from "react";
import iconLogoFull from "../../assets/logo-full.png";
import { useSetRecoilState } from "recoil";
import { atom__searchOfficeModalInfo } from "../../lib/recoil/modal.atom";
import BaseButton from "../../components/Button/Base";
import ModalSimpleAlert from "../../components/Modal/SimpleAlert";
import { useLocation, useNavigate } from "react-router-dom";
import BaseButtonOutline from "../../components/Button/Outline";
import Select from "../../components/Select";
import { api_membershipItem } from "../../api/membership";
import { regNumber } from "../../lib/validator";
import FileInput from "../../components/FileInput";
import FakeSearchInput from "../../components/FakeSearchInput";
import { api_register } from "../../api/auth";
import { uploadFile } from "../../lib/upload-file";

const RegisterStep2Page = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const r__setSearchOfficeModalInfo = useSetRecoilState(
    atom__searchOfficeModalInfo
  );

  const [canNext, setCanNext] = useState(false);

  const [selectedUserKind, setSelectedUserKind] = useState("none"); // "개업공인중개사" | "소속공인중개사" | "중개보조원" | "none"
  const [membershipItems, setMembershipItems] = useState([
    { label: "멤버십 선택", value: "none" },
  ]);
  const [selectedMembershipId, setSelectedMembershipId] = useState("none");

  const [file1, setFile1] = useState<File | undefined>();
  const [file2, setFile2] = useState<File | undefined>();
  const [file3, setFile3] = useState<File | undefined>();

  const [selectedOffice, setSelectedOffice] = useState({ id: "", name: "" });

  const [officeHp1, setOfficeHp1] = useState("");
  const [officeHp2, setOfficeHp2] = useState("");
  const [officeHp3, setOfficeHp3] = useState("");

  useEffect(() => {
    // 멤버십 목록 fetch
    api_membershipItem().then((res) => {
      if (!res) return;

      setMembershipItems([
        { label: "멤버십 선택", value: "none" },
        ...res.list.map((m) => ({ label: m.name, value: m.id })),
      ]);
    });
  }, []);

  useEffect(() => {
    const common =
      selectedUserKind !== "none" &&
      !!file2 &&
      !!selectedMembershipId &&
      officeHp1.length >= 2 &&
      officeHp2.length > 2 &&
      officeHp3.length > 2;
    const master = common && !!file1 && !!file3;
    const slave = common && !!file3;
    const support = common;
    setCanNext(
      selectedUserKind === "개업공인중개사"
        ? master
        : selectedUserKind === "소속공인중개사"
        ? slave
        : support
    );
  }, [
    selectedUserKind,
    file1,
    file2,
    file3,
    selectedMembershipId,
    officeHp1,
    officeHp2,
    officeHp3,
  ]);

  const next = async () => {
    const { uid, pw, name, phone, email } = location.state.registerInfo;

    let imageUrlBusiness: string | undefined;
    let imageUrlOffice: string;
    let imageUrlCert: string | undefined;

    // 이미지(사업자등록증): 개공만
    if (selectedUserKind === "개업공인중개사") {
      if (!file1) return;
      const uploadFile1 = await uploadFile(file1);
      if (!uploadFile1) return;
      imageUrlBusiness = uploadFile1.url;
    }

    // 이미지(사무소등록증): 모두
    if (!file2) return;
    const uploadFile2 = await uploadFile(file2);
    if (!uploadFile2) return;
    imageUrlOffice = uploadFile2.url;

    // 이미지(중개사자격증): 개공,소공만
    if (selectedUserKind !== "중개보조원") {
      if (!file3) return;
      const uploadFile3 = await uploadFile(file3);
      if (!uploadFile3) return;
      imageUrlCert = uploadFile3.url;
    }

    api_register({
      officeId: selectedOffice?.id ?? undefined,
      membershipId: selectedMembershipId,
      userKind: selectedUserKind,
      user: {
        uid,
        pw,
        name,
        phone,
        email,
        officeHp: `${officeHp1}-${officeHp2}-${officeHp3}`,
        imageUrlBusiness,
        imageUrlOffice,
        imageUrlCert,
      },
    }).then((res) => {
      if (!res) return;
      navigate("/register/complete", { state: { name } });
    });
  };

  return (
    <div
      className="flex-col-start-center"
      style={{
        height: "100%",
        width: "100%",
        padding: "20px",
      }}
    >
      <div
        className="flex-col-start-center"
        style={{ width: "344px", position: "relative" }}
      >
        <img src={iconLogoFull} style={{ width: "100%" }} />

        <h2 style={{ margin: "20px 0" }}>회원가입</h2>

        <div className="flex-row-between-center" style={{ width: "100%" }}>
          <label>유형</label>
        </div>
        <Select
          items={[
            { label: "유형 선택", value: "none" },
            { label: "개업공인중개사", value: "개업공인중개사" },
            { label: "소속공인중개사", value: "소속공인중개사" },
            { label: "중개보조원", value: "중개보조원" },
          ]}
          value={selectedUserKind}
          onChange={(v) => setSelectedUserKind(v)}
          style={{ marginBottom: "14px" }}
        />

        {selectedUserKind === "개업공인중개사" && (
          <>
            <div className="flex-row-between-center" style={{ width: "100%" }}>
              <label>사업자등록증</label>
            </div>
            <FileInput
              onChange={(f) => {
                setFile1(f);
              }}
              style={{ marginBottom: "14px" }}
            />
          </>
        )}

        <div className="flex-row-between-center" style={{ width: "100%" }}>
          <label>중개사무소등록증</label>
        </div>
        <FileInput
          onChange={(f) => {
            setFile2(f);
          }}
          style={{ marginBottom: "14px" }}
        />

        {selectedUserKind !== "중개보조원" && (
          <>
            <div className="flex-row-between-center" style={{ width: "100%" }}>
              <label>중개사 자격증</label>
            </div>
            <FileInput
              onChange={(f) => {
                setFile3(f);
              }}
              style={{ marginBottom: "14px" }}
            />
          </>
        )}

        <div className="flex-row-between-center" style={{ width: "100%" }}>
          <label>소속 중개사무소 (선택)</label>
        </div>
        <FakeSearchInput
          label={selectedOffice.name}
          placeholder="중개사무소 검색"
          onClick={() => {
            r__setSearchOfficeModalInfo({
              onSelect: (id, name) => {
                setSelectedOffice({ id, name });
              },
            });
          }}
          style={{
            marginBottom: "14px",
          }}
        />

        <div className="flex-row-between-center" style={{ width: "100%" }}>
          <label>구매희망이용권 (멤버십)</label>
        </div>
        <Select
          items={membershipItems}
          value={selectedMembershipId}
          onChange={(v) => setSelectedMembershipId(v)}
          style={{ marginBottom: "14px" }}
        />

        <div className="flex-row-between-center" style={{ width: "100%" }}>
          <label>대표번호 (유선)</label>
        </div>
        <div
          className="flex-row-between-center"
          style={{ width: "100%", marginBottom: "2px" }}
        >
          <input
            id="officeHp1"
            type="text"
            placeholder="031"
            maxLength={3}
            style={{ textAlign: "center", flex: 1, maxWidth: "100px" }}
            value={officeHp1}
            onChange={(e) => {
              if (e.target.value.length && !regNumber.test(e.target.value))
                return;
              setOfficeHp1(e.target.value);
            }}
          />

          <input
            id="officeHp2"
            type="text"
            placeholder="1234"
            maxLength={4}
            style={{ textAlign: "center", flex: 1, maxWidth: "100px" }}
            value={officeHp2}
            onChange={(e) => {
              if (e.target.value.length && !regNumber.test(e.target.value))
                return;
              setOfficeHp2(e.target.value);
            }}
          />

          <input
            id="officeHp3"
            type="text"
            placeholder="1234"
            maxLength={4}
            style={{ textAlign: "center", flex: 1, maxWidth: "100px" }}
            value={officeHp3}
            onChange={(e) => {
              if (e.target.value.length && !regNumber.test(e.target.value))
                return;
              setOfficeHp3(e.target.value);
            }}
          />
        </div>
        <p className="alert-desc" style={{ width: "100%" }}>
          *중개사 개인 배정 유선전화가 아닌 사무실 대표 유선전화를 입력바랍니다.
        </p>
        <p
          className="alert-desc"
          style={{ width: "100%", marginBottom: "20px" }}
        >
          오기재시 가입요청이 반려될 수 있습니다.
        </p>

        <div
          className="flex-row-center-center"
          style={{ gap: "14px", marginBottom: "14px", width: "100%" }}
        >
          <BaseButtonOutline
            style={{ flex: 1 }}
            text="이전"
            onClick={() => {
              navigate(-1);
            }}
          />
          <BaseButton
            text="가입신청"
            onClick={next}
            disabled={!canNext}
            style={{ flex: 1 }}
          />
        </div>
      </div>

      <ModalSimpleAlert />
    </div>
  );
};

export default RegisterStep2Page;
